<template>
	<div class="tg-foot flex-container fixed-bottom bg-white bordert">
		<div class="flex1 text-center"
			 :data-scp="item.scpC+'.00'"
			 :key="index"
			 v-for="(item,index) in list">
			<div v-if="item.icon==='icon-live'&&isWeixin">
				<wx-open-launch-weapp :username="webcast.userName"
									  :path="webcast.path">
					<script type="text/wxtag-template">
						<img src="{{protocol}}//image1.51tiangou.com/tgou2/img2/webcast/icon-webcast.png"
							 width="26"
							 height="22"
							 style="display: block;margin: 2px auto 0;"
							 alt="">
						<div style="font-size: 11px;color: #666;margin: 5px 0 2px;">{{item.title}}</div>
					</script>
				</wx-open-launch-weapp>
			</div>
			<div v-else @click="change(item)">
				<i class="icon dis-b" style="font-size: 26px;" :class="getIcon(item)" v-if="getIcon(item) !== 'icon-shopping-current'"></i>
				<span class="fs-10" v-if="getIcon(item) !== 'icon-shopping-current'">{{item.title}}</span>
                <img v-if="getIcon(item) === 'icon-shopping-current'" width="40"
                     src="//image1.51tiangou.com/tgou2/img2/index/icon-shop-cur.png" alt="">
			</div>
		</div>
	</div>
</template>
<style type="text/scss" lang="scss" scoped>
.tg-foot{
	color:#000;
	height:50px;
	border-top: 1px solid #e0e0e0;
	z-index:2;
}
</style>
<script>
import domain from 'javascript/libs/tg/domain.js';

export default {
	name:'foot',
	props: {}
	,
	data(){
		return {
			list:[{
                title:'购物',
                icon:'icon-shopping',
                iconCur: 'icon-shopping-current',
                scpC:'navS',
                link:'/index.html',
            }, {
                title:'海外购',
                icon:'icon-index_worldwide',
                scpC: 'navE',
                link: domain.env === 'test' ? '/activity.html#/index?contentId=201' : domain.env === 'pre' ? '/activity.html#/index?contentId=33' : '/activity.html#/index?contentId=1857'
            }, {
				title:'逛店',
				icon:'icon-store',
                iconCur: 'icon-store-current',
				scpC:'navH',
				link:'/store.html'
                // }, {
                // 	title:'直播',
                // 	icon:'icon-live',
                // 	scpC:'navC',
                // 	link:'/webcast.html#/publicity'
			},  {
				title: '购物车'
				, icon: 'icon-cart'
				, scpC: 'navG'
				, link: '/purchase/index.html'
			}, {
				title:'我的',
				icon:'icon-member',
                iconCur: 'icon-member-current',
				scpC:'navM',
				link:'/mine/index.html'
			}]
			, isWeixin: maple.device.weixin
			, webcast: {
				userName: domain.webcast.userName
				, path: `${domain.webcast.path}.html`
			}
			, protocol: location.protocol
		};
	},
	methods:{
        getIcon(item) {
            return _.includes(location.pathname,item.link) || (location.pathname === '/' && item.link === '/index.html') ? item.iconCur : item.icon;
        },
		change(item){
			if(maple.url().path!==item.link){
				tg.changePage(item.link,{
					scp:tg.page+'.'+item.scpC+'.00'
				});
			}
		}
	}
};
</script>
