import Super from "./class";

let serverList = {
  get: {
    detail: '/publics/coupon/detail',
    query: '/publics/couponCode/query'
    // 自营品牌
    , autotrophy: '/publics/wx/applet/recommend/brand/query'
    
    // 客服推送优惠券
    , servicePush: '/publics/customer/coupon/query'
    , vipCouponProduct: '/publics/vip/coupon/product' // 首开卡优惠
    , getVipCouponList: '/publics/vip/present/coupon', // 开通会员赠送的券
    // 查页面可展示的红包雨活动
    getRedPacketActivity: '/publics/red/packet/home',
    // 校验该红包雨活动用户是否可展示
    isShowRedPacketActivity: '/publics/red/packet/check/show',
    // 商品水印
    getWatermarkByItemList: '/front/listing/batchGetTag'
  },
  post: {
    activityDetail: '/publics/activity/detail',
    couponSearch: '/publics/couponCode/search',
    getActivityCoupon: '/publics/activity/coupon/list',
    add: '/publics/couponCode/add',
    getTopSalesVolumeProduct: '/publics/activity/groupPurchase/getTopSalesVolumeProduct',
    getGroupInfoByOrderId: '/publics/activity/groupPurchase/getGroupInfoByOrderId',
    getGroupPurchaseDetail: '/publics/activity/groupPurchase/getGroupPurchaseDetail',
    getGroupPurchaseInfo: '/publics/activity/groupPurchase/getGroupPurchaseInfo',
    getItemInfoByOrderId: '/publics/activity/groupPurchase/getItemInfoByOrderId',
    getItemInfoByGroupId: '/publics/activity/groupPurchase/getItemInfoByGroupId',
    getContentInfo: '/publics/content/getContentInfo',
    getItemListInfo: '/publics/content/getItemListInfo',
    getPromotionByItemList: '/publics/promotion/getPromotionByItemList',
    getStoreList: '/publics/content/getStoreList',
    advModule: '/publics/tgAd/advModule',
    queryByIdList: '/publics/coupon/queryByIdList',
    maotaiProduct: '/publics/activity/moutai/product',
    getRenewInfo: '/publics/renew/vip'
    , byIdList: '/publics/coupon/byIdList'
    , promotionByItemList: '/publics/promotion/promotionSimpleByItemList'
    , itemHot: '/publics/activity/item/hot'
    , vipDayProduct: '/publics/content/vipDay/product' // 会员日特卖商品
    // , dailySpeciallySeckill: '/publics/activity/vip/secKill', // 每日秒杀
    , dailySpeciallySeckill: '/publics/card/product/queryVipProducts', // 每日秒杀
    // 记录已给用户展示该红包雨活动
    hasShowRedPacketActivity: '/publics/red/packet/show',
    // 红包雨活动抽奖
    lotteryRedPacket: '/publics/red/packet/lottery'
  },
};
export default new Super(serverList, 'coupon');
