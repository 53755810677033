/**
 * Created by chenqifeng on 2016/8/30.
 */

let hostname = location.hostname
    , test
    , tgDomain = ''
    , ctx = ''
    , env = ''
    , baseMidService
    , baseService = ''
    , appId = ''
    , openAppId = ''
    , urlExpectedCode = [
        'custom'    // 标识是否为电子入口；
        , 'uuid'    // 标识电子入口标识
        , 'source_type' // 标识微信订阅号/服务号
        , 'source_weixin_id'    // 标识微信原始id
        // , 'code' // 用于微信获取openid
        , 'state'   // 用于微信获取 openid
        , 'bd_source_light' // 百度直达号
        , 'bd_wd'   // 百度直达号
    ]
    , webcast   // 天狗小程序-直播、结算码
    , scan  // 扫码购小程序
    , overSea // 海外购小程序
    ;

// 线上
if( hostname === 'm.51tiangou.com' || hostname.indexOf('51tiangou') > 0 ){
    env = 'online';
    tgDomain = '51tiangou.com';
    baseService = '//m.51tiangou.com';
    baseMidService = '//midway.51tiangou.com';
    appId = 'wx1c18f2760c5ff068';
    openAppId = 'wx4f113b1e6b172f5e';
    test = false;
    webcast = { // 直播小程序
        // 小程序原始id
        "userName": "gh_b3c00df3bb72",
        // 拉起小程序页面路径，可带参数
        path: '/pages/liveslist/liveslist'
        , room: '/subpackages/customer/lives/livesplay/livesplay'
        , barCode: '/subpackages/customer/jiesuanma/barcode/barcode' // 结算码
        , coupon: '/subpackages/customer/guangdian/zhulicoupon/zhulicoupon'
        , payOrder: '/subpackages/customer/purchase/prepay/prepay'
        , product: '/subpackages/customer/product/productdetail/productdetail'  // 商品详情页
        , maotai: '/subpackages/customer/moutai/qualification/qualification'
        , lipinka: '/subpackages/customer/lipinka/mylpklist/mylpklist',
        // 扫码购页面
        scan: '/subpackages/customer/saomagou/home/home',
        // 互动投票
        vote: '/subpackages/customer/plaza/plaza/plaza',
        // 电子大商卡
        dsCard: '/subpackages/customer/dzdsk/dskhome/dskhome',
        // 0 正式版本，1 开发版本，2 体验版本
        type: 0
    };
    scan = {
        userName: 'gh_fb2a3b59a40c'
        , path: '/pages/home/home'
        , type: 0
    };
    overSea = {
        'userName': 'gh_16c558142500'
        , type: 0
        , content: {
            path: '/subpackages/customer/contentmodels/contentmodel/contentmodel'
            , params: [['id', 'contentId']]
        }
        , activity: {
            path: '/subpackages/customer/contentmodels/activitymodel/activitymodel'
            , params: ['id']
        }
        , limit: {
            path: '/subpackages/customer/xianshifengqiang/xianshifengqianglist/xianshifengqianglist'
            , params: ['id', 'pageType', 'itemId']
        }
        , product: {
            path: '/subpackages/customer/product/productdetail/productdetail'
            , params: [['productId', 'id'], 'activityId', 'groupShow']
        }
    };
}
// 预发
else if( hostname.indexOf('pre.66buy.com.cn') > 0 ){
    env = 'pre';
    tgDomain = 'pre.66buy.com.cn';
    baseService = '//m.pre.66buy.com.cn';
    baseMidService = '//midway.pre.66buy.com.cn';
    appId = 'wxd13f25106e193648';
    openAppId = 'wxff6dd0fa2c5c8028';
    test = false;
    webcast = {
        // 小程序原始id
        "userName": "gh_b3c00df3bb72",  // 体验版本
        // 拉起小程序页面路径，可带参数
        path: '/pages/liveslist/liveslist'
        , room: '/subpackages/customer/lives/livesplay/livesplay'
        , barCode: '/subpackages/customer/jiesuanma/barcode/barcode' // 结算码
        , coupon: '/subpackages/customer/guangdian/zhulicoupon/zhulicoupon'
        , payOrder: '/subpackages/customer/purchase/prepay/prepay'
        , product: '/subpackages/customer/product/productdetail/productdetail'  // 商品详情页
        , maotai: '/subpackages/customer/moutai/qualification/qualification'
        , lipinka: '/subpackages/customer/lipinka/mylpklist/mylpklist',
        // 扫码购页面
        scan: '/subpackages/customer/saomagou/home/home',
        // 互动投票
        vote: '/subpackages/customer/plaza/plaza/plaza',
        // 电子大商卡
        dsCard: '/subpackages/customer/dzdsk/dskhome/dskhome',
        // 0 正式版本，1 开发版本，2 体验版本
        type: 2
    };
    scan = {
        userName: 'gh_fb2a3b59a40c'
        , path: '/pages/home/home'
        , type: 2
    };
    overSea = {
        'userName': 'gh_16c558142500'
        , type: 2
        , content: {
            path: '/subpackages/customer/contentmodels/contentmodel/contentmodel'
            , params: [['id', 'contentId']]
        }
        , activity: {
            path: '/subpackages/customer/contentmodels/activitymodel/activitymodel'
            , params: ['id']
        }
        , limit: {
            path: '/subpackages/customer/xianshifengqiang/xianshifengqianglist/xianshifengqianglist'
            , params: ['id', 'pageType', 'itemId']
        }
        , product: {
            path: '/subpackages/customer/product/productdetail/productdetail'
            , params: [['productId', 'id'], 'activityId', 'groupShow']
        }
    };
}
// 需要本地调试中间件时再打开
// 不要提交
// 本地
// else if( hostname === 'localtgou.test.66buy.com.cn' ){
//     env = 'test';
//     tgDomain = 'test.66buy.com.cn';
//     baseService = '//localtgou.test.66buy.com.cn';
//     baseMidService = '//localmidway.test.66buy.com.cn:3001';
//     appId = 'wx3bc8b36ed3f965bd';
//     openAppId = 'wxff6dd0fa2c5c8028';
//     test = true;
//     webcast = {
//         // 小程序原始id
//         'userName': 'gh_2625060c6493',   // 开发版本
//         // 拉起小程序页面路径，可带参数
//         path: '/pages/liveslist/liveslist'
//         , room: '/subpackages/customer/lives/livesplay/livesplay'
//         , barCode: '/subpackages/customer/jiesuanma/barcode/barcode' // 结算码
//         , coupon: '/subpackages/customer/guangdian/zhulicoupon/zhulicoupon'
//         , payOrder: '/subpackages/customer/purchase/prepay/prepay'
//         , product: '/subpackages/customer/product/productdetail/productdetail'  // 商品详情页
//         , maotai: '/subpackages/customer/moutai/qualification/qualification',
//         // 扫码购页面
//         scan: '/subpackages/customer/saomagou/home/home',
//         // 互动投票
//         vote: '/subpackages/customer/plaza/plaza/plaza',
//         // 电子大商卡
//         dsCard: '/subpackages/customer/dzdsk/dskhome/dskhome',
//         // 0 正式版本，1 开发版本，2 体验版本
//         type: 1
//     };
//     scan = {
//         userName: 'gh_35b8687bd742'
//         , path: '/pages/home/home'
//         , type: 1
//     };
//     overSea = {
//         'userName': 'gh_98dc8633842c'
//          , type: 1
//     };
// }
// 测试
else if( hostname.indexOf('test.66buy.com.cn') > 0 ){
    env = 'test';
    tgDomain = 'test.66buy.com.cn';
    baseService = '//m.test.66buy.com.cn';
    baseMidService = '//midway.test.66buy.com.cn';
    appId = 'wx3bc8b36ed3f965bd';
    openAppId = 'wxff6dd0fa2c5c8028';
    test = true;
    webcast = {
        // 小程序原始id
        "userName": "gh_2625060c6493",   // 开发版本
        // 拉起小程序页面路径，可带参数
        path: '/pages/liveslist/liveslist'
        , room: '/subpackages/customer/lives/livesplay/livesplay'
        , barCode: '/subpackages/customer/jiesuanma/barcode/barcode' // 结算码
        , coupon: '/subpackages/customer/guangdian/zhulicoupon/zhulicoupon'
        , payOrder: '/subpackages/customer/purchase/prepay/prepay'
        , product: '/subpackages/customer/product/productdetail/productdetail'  // 商品详情页
        , maotai: '/subpackages/customer/moutai/qualification/qualification'
        , lipinka: '/subpackages/customer/lipinka/mylpklist/mylpklist',
        // 扫码购页面
        scan: '/subpackages/customer/saomagou/home/home',
        // 互动投票
        vote: '/subpackages/customer/plaza/plaza/plaza',
        // 电子大商卡
        dsCard: '/subpackages/customer/dzdsk/dskhome/dskhome',
        // 0 正式版本，1 开发版本，2 体验版本
        type: 1
    };
    scan = {
        userName: 'gh_35b8687bd742'
        , path: '/pages/home/home'
        , type: 1
    };
    overSea = {
        "userName": "gh_98dc8633842c"
        , type: 1
        , content: {
            path: '/subpackages/customer/contentmodels/contentmodel/contentmodel'
            , params: [['id', 'contentId']]
        }
        , activity: {
            path: '/subpackages/customer/contentmodels/activitymodel/activitymodel'
            , params: ['id']
        }
        , limit: {
            path: '/subpackages/customer/xianshifengqiang/xianshifengqianglist/xianshifengqianglist'
            , params: ['id', 'pageType', 'itemId']
        }
        , product: {
            path: '/subpackages/customer/product/productdetail/productdetail'
            , params: [['productId', 'id'], 'activityId', 'groupShow']
        }
    };
}
// 开发
else if( hostname.indexOf('dev.66buy.com.cn') > 0 ){
    env = 'dev';
    tgDomain = 'dev.66buy.com.cn';
    baseService = '//m.dev.66buy.com.cn';
    baseMidService = '//midway.dev.66buy.com.cn';
    appId = 'wx3bc8b36ed3f965bd';
    test = true;
    webcast = {
        // 小程序原始id
        "userName": "gh_2625060c6493",
        // 拉起小程序页面路径，可带参数
        path: '/pages/liveslist/liveslist'
        , room: '/subpackages/customer/lives/livesplay/livesplay'
        , barCode: '/subpackages/customer/jiesuanma/barcode/barcode' // 结算码
        , coupon: '/subpackages/customer/guangdian/zhulicoupon/zhulicoupon'
        , payOrder: '/subpackages/customer/purchase/prepay/prepay'
        , product: '/subpackages/customer/product/productdetail/productdetail'  // 商品详情页
        , maotai: '/subpackages/customer/moutai/qualification/qualification'
        , lipinka: '/subpackages/customer/lipinka/mylpklist/mylpklist',
        // 扫码购页面
        scan: '/subpackages/customer/saomagou/home/home',
        // 互动投票
        vote: '/subpackages/customer/plaza/plaza/plaza',
        // 电子大商卡
        dsCard: '/subpackages/customer/dzdsk/dskhome/dskhome',
        // 0 正式版本，1 开发版本，2 体验版本
        type: 1
    };
    scan = {
        userName: 'gh_35b8687bd742'
        , path: '/pages/home/home'
        , type: 1
    };
    overSea = {
        "userName": "gh_98dc8633842c"
        , type: 1
        , content: {
            path: '/subpackages/customer/contentmodels/contentmodel/contentmodel'
            , params: [['id', 'contentId']]
        }
        , activity: {
            path: '/subpackages/customer/contentmodels/activitymodel/activitymodel'
            , params: ['id']
        }
        , limit: {
            path: '/subpackages/customer/xianshifengqiang/xianshifengqianglist/xianshifengqianglist'
            , params: ['id', 'pageType', 'itemId']
        }
        , product: {
            path: '/subpackages/customer/product/productdetail/productdetail'
            , params: [['productId', 'id'], 'activityId', 'groupShow']
        }
    }
}
else if( hostname.indexOf('onlinet.66buy.com.cn') > 0 ){
    /**
     * 为了热力图添加
     * */
    env = 'online';
    tgDomain = 'onlinet.66buy.com.cn';
    baseService = '//m.51tiangou.com';
    baseMidService = '//midway.onlinet.66buy.com.cn';
    appId = 'wx1c18f2760c5ff068';
    openAppId = 'wx4f113b1e6b172f5e';
    test = false;
    webcast = {
        // 小程序原始id
        "userName": "gh_b3c00df3bb72",
        // 拉起小程序页面路径，可带参数
        path: '/pages/liveslist/liveslist'
        , room: '/subpackages/customer/lives/livesplay/livesplay'
        , barCode: '/subpackages/customer/jiesuanma/barcode/barcode' // 结算码
        , coupon: '/subpackages/customer/guangdian/zhulicoupon/zhulicoupon'
        , payOrder: '/subpackages/customer/purchase/prepay/prepay'
        , product: '/subpackages/customer/product/productdetail/productdetail'  // 商品详情页
        , maotai: '/subpackages/customer/moutai/qualification/qualification'
        , lipinka: '/subpackages/customer/lipinka/mylpklist/mylpklist',
        // 扫码购页面
        scan: '/subpackages/customer/saomagou/home/home',
        // 互动投票
        vote: '/subpackages/customer/plaza/plaza/plaza',
        // 电子大商卡
        dsCard: '/subpackages/customer/dzdsk/dskhome/dskhome',
        // 0 正式版本，1 开发版本，2 体验版本
        type: 2
    };
    scan = {
        userName: 'gh_fb2a3b59a40c'
        , path: '/pages/home/home'
        , type: 2
    };
}
else{
    env = 'online';
    tgDomain = '51tiangou.com';
    baseService = '//m.51tiangou.com';
    baseMidService = '//midway.51tiangou.com';
    appId = 'wx1c18f2760c5ff068';
    openAppId = 'wx4f113b1e6b172f5e'
    test = false;
    webcast = {
        // 小程序原始id
        "userName": "gh_b3c00df3bb72",
        // 拉起小程序页面路径，可带参数
        path: '/pages/liveslist/liveslist'
        , room: '/subpackages/customer/lives/livesplay/livesplay'
        , barCode: '/subpackages/customer/jiesuanma/barcode/barcode' // 结算码
        , coupon: '/subpackages/customer/guangdian/zhulicoupon/zhulicoupon'
        , payOrder: '/subpackages/customer/purchase/prepay/prepay'
        , product: '/subpackages/customer/product/productdetail/productdetail'  // 商品详情页
        , maotai: '/subpackages/customer/moutai/qualification/qualification',
        // 扫码购页面
        scan: '/subpackages/customer/saomagou/home/home',
        // 互动投票
        vote: '/subpackages/customer/plaza/plaza/plaza',
        // 电子大商卡
        dsCard: '/subpackages/customer/dzdsk/dskhome/dskhome',
        // 0 正式版本，1 开发版本，2 体验版本
        type: 0
    };
    scan = {
        userName: 'gh_fb2a3b59a40c'
        , path: '/pages/home/home'
        , type: 0
    };
    overSea = {
        "userName": "gh_16c558142500"
        , type: 0
        , content: {
            path: '/subpackages/customer/contentmodels/contentmodel/contentmodel'
            , params: [['id', 'contentId']]
        }
        , activity: {
            path: '/subpackages/customer/contentmodels/activitymodel/activitymodel'
            , params: ['id']
        }
        , limit: {
            path: '/subpackages/customer/xianshifengqiang/xianshifengqianglist/xianshifengqianglist'
            , params: ['id', 'pageType', 'itemId']
        }
        , product: {
            path: '/subpackages/customer/product/productdetail/productdetail'
            , params: [['productId', 'id'], 'activityId', 'groupShow']
        }
    }
}

export default {
    mock: false,
    mockId: 1060,
    baseMidService: baseMidService,
    urlExpectedCode: urlExpectedCode,
    env: env,
    tgDomain: tgDomain,
    baseService: baseService,
    test: !(env === 'online' || env === 'pre'),
    ctx: ctx,
    openAppId: openAppId,
    appId: appId,
    /**
     * 获取访问图片的方式, 默认原图
     * @param url ： base url
     * @param type ： 图片的size ： s， m ，v， y
     */
    getImageUrl: function (url, type) {
        type = type || "y";
        var imageUrl = '',
            product_url = test ? "//test.img.tg-img.com/" : "//image1.51tiangou.com/",
        //product_url = "//image1.51tiangou.com/",
            prefix = test ? {e: "", s: "", v: "", m: "", y: "", "640x260": "", "580x220": ""} : {},
            postfix = {
                e: "!e",
                s: "!s",
                v: "!y",
                m: "!m",
                y: "!y"/*"!y"*/,
                "640x260": "!640x260",
                "580x220": "!580x220"
            };
        if (url) {
            if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0){
	            imageUrl = url + (postfix[type] || "");
            }
            else if( url.indexOf('//') === 0 && /!.$/.test(url) ){    // 中间件已经处理
	            imageUrl = url
            } else {
                imageUrl = product_url + (prefix[type] || "") + url + (postfix[type] || "");
            }
        }
        return imageUrl;
    },
    getLinkHref: function (clickUrl) {
        var r = '';
        if (clickUrl) {
            if (clickUrl.indexOf("http://") === 0 || clickUrl.indexOf("https://") === 0) {
                r = clickUrl;
            } else if (clickUrl.indexOf("http://") === 1 || clickUrl.indexOf("https://") === 1) {
                r = clickUrl.slice(1);
            } else {
                r = ctx + clickUrl;
            }
        }
        return r;
    }
    , webcast: webcast
    , scan: scan
    , overSea: overSea
};
