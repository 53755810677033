import Super from "./class";

let serverList = {
  get: {
    tgVipSaveMoney: '/web/vip/saveMoney',
    // 查询订单支付状态
    checkVipPay: '/publics/order/check/paid'
  },
  post: {
    preOrder: '/publics/tgouOrder/add'
    , toHomeCart: '/publics/toHome/cartList'
    , updateTohomeCart: '/publics/toHome/updateCart'
    , clearTohomeCart: '/publics/toHome/deleteShopCart'
    , validate: '/publics/tgouOrder/validate'
    , addShopCart: '/publics/shopCart/add'  // 添加到购物车
    , productTax: '/publics/tgouOrder/webProductTax'   // 获取保税商品的税率
    , prePayOrderInfo: '/publics/tgouOrder/prePayOrderInfo'    // 收银台获取订单信息
    , orderPay: '/publics/tgouOrder/pay'    // 收银台普通订单支付
    , getPayCode: '//publics/tgouOrder/getPayCode'    // 收银台获取到店自提核销码
    , canBuyLimit: '/publics/tgouOrder/maotai/canBuyLimit'
    , maotaiRule: '/publics/tgouOrder/maotai/rule'
    , validateVip: '/publics/tgouOrder/vipOrder/validate'
    , vipOrder: '/publics/tgouOrder/vipOrder/add'
    , vipOrderInfo: '/publics/tgouOrder/vipOrder/prePayOrderInfo'
    , vipPay: '/publics/tgouOrder/vip/pay'
    , canBuyDetail:'/publics/tgouOrder/maotai/canBuyDetail'
    , signUpmsg:'/web/moutai/signUpMsg'
    , sigup:'/web/moutai/signUp'

    , tgVipOrder: '/web/vip/add'
    , tgVipPay: '/web/vip/pay'
  },
};
export default new Super(serverList, 'oserv');
