<template>
<div class="paddingv20">
    <form action="" @submit.stop.prevent="search" class="pos-r paddingh20">
        <cmui-input type="search"
                    align="right"
                    target-class="small radius"
                    class="marginb20 searchNew"
                    :placeholder="placeholder"
                    v-model="value"
                    @input="inputChange">
            <span class="marginl20 text-dark" @click="cancelSearch">取消</span>
        </cmui-input>
        <a href="/store.html#/selectStore"
           class="pos-a fs-13 text-darker"
           style="right:62px;top:6px;"
           v-show="!value"
           data-scp="searchshop.0"
           :data-bk="`type-${type}`">
	        <i class="icon icon-search_shop" style="margin-right:3px"></i>搜门店
        </a>
    </form>
    <div class="paddingv10 paddingh20 clearfix pos-r"
         v-if="_.get(history,'length')"
         style="overflow: hidden"
         :style="historySearchType === 1 ? 'max-height: 76px;' : 'max-height: 110px;'">
        <p class="paddingb10 text-bolder">最近搜索</p>
        <div class="pos-a" style="right:10px;top:7px;" @click="clearHistory">
            <i class="icon icon-delete text-light"></i>
        </div>
        <span class="btn-search"
              v-for="(item, index) in history"
              @click="toResult(item)"
              :data-bk="`type-${type}`"
              :data-scp="`freshData.${index}`">{{item}}</span>
    </div>
    <maibisheng :data="product"
                :from="type"
                v-if="product.length > 0"></maibisheng>
    <div class="clearfix" v-if="_.get(hotWords, 'length')">
        <p class="paddingt40 paddingh20 text-bolder">热门搜索</p>
        <!-- <div class="container">
            <a class="span6 fs-13 margint30"
               :class="item.hot === 0?'text-red':'text-dark'"
               :href="item.clickUrl"
               :data-bk="`type-${type}`"
               v-for="(item, index) in hotWords"
               :data-scp="`hotData.${index}`"
               :data-trace-id="item.traceId">{{item.title}}</a>
        </div> -->
        <div class="marginh20 margint30 scroll-container" v-if="type === 'index'">
            <div class="dis-b">
                <div v-for="(item, index) in hotWordsUp"
                     class="dis-i" style="min-width: 160px;padding: 4px;margin-right: 8px">
                    <a :href="item.clickUrl"
                       :data-bk="`type-${type}`"
                       :data-scp="`hotData.${2*index}`"
                       :data-trace-id="item.traceId"
                       class="flex-container left searchHot br8 padding10">
                        <div class="img-container" style="width:50px;height:50px">
                            <img :src="item.imageUrl" style="border-radius:4px;" alt="">
                        </div>
                        <div class="marginl10">
                            <div class="text-limit1 fs-12" :class="item.hot === 0?'text-red':'text-black'">
                                {{ item.title.slice(0, 7) }}
                            </div>
                            <div class="text-limit1 fs-10" style="color:#848484;">{{ item.subTitle }}</div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="dis-b">
                <div v-for="(item, index) in hotWordsLow"
                     class="dis-i" style="min-width: 160px;padding: 4px;margin-right: 8px">
                    <a :href="item.clickUrl"
                       :data-bk="`type-${type}`"
                       :data-scp="`hotData.${2*index+1}`"
                       :data-trace-id="item.traceId"
                       class="flex-container left searchHot br8 padding10">
                        <div class="img-container" style="width:50px;height:50px">
                            <img :src="item.imageUrl" style="border-radius:4px;" alt="">
                        </div>
                        <div class="marginl10">
                            <div class="text-limit1 fs-12" :class="item.hot === 0?'text-red':'text-black'">
                                {{ item.title.slice(0, 7) }}
                            </div>
                            <div class="text-limit1 fs-10" style="color:#848484;">{{ item.subTitle }}</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <cmui-list :col="2" :space="20" class="marginh20 margint30" v-else>
            <cmui-list-item v-for="(item, index) in hotWords">
                <a :href="item.clickUrl"
                   :data-bk="`type-${type}`"
                   :data-scp="`hotData.${index}`"
                   :data-trace-id="item.traceId"
                   class="flex-container left searchHot br8 padding10">
                    <div class="img-container" style="width:50px;height:50px">
                        <img :src="item.imageUrl" style="border-radius:4px;" alt="">
                    </div>
                    <div class="marginl10">
                        <div class="text-limit1 fs-14" :class="item.hot === 0?'text-red':'text-black'">{{item.title}}</div>
                        <div class="text-limit1 fs-10" style="color:#848484;">{{item.subTitle}}</div>
                    </div>
                </a>
            </cmui-list-item>
        </cmui-list>
    </div>
    <div v-if="recProducts.length">
        <p class="paddingt40 paddingh20 text-bolder">为您推荐</p>
        <cmui-list :col="3" :space="10" class="marginh20 margint30">
            <cmui-list-item v-for="(item, index) in recProducts">
                <a :href="item.clickUrl"
                   :data-bk="`item-${item.id}`"
                   :data-scp="`ylike.${index}`"
                   :data-trace-id="item.traceId"
                   class="dis-b marginb10 border-radius border">
                    <div class="img-container">
                        <img :src="item.imageUrl"
                             onerror="this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'"
                             alt="">
                    </div>
                    <div class="padding10">
                        <p class="text-fixed2 fs-12 text-darker"
                           style="line-height: 1.2;height: 2.4em;"
                           v-text="item.title"></p>
                        <div class="flex-container left">
                            <span class="text-red fs-12" v-if="item.newPrice"><span class="fs-15">¥{{ item.newPrice }} 新人价</span></span>
                            <span class="text-red fs-15" v-else>¥{{ item.price }}</span>
                            <del class="text-light fs-11 marginl10"
                                 v-text="'¥'+item.originalPrice"
                                 v-if="item.price !== item.originalPrice && !item.newPrice"></del>
                        </div>
                    </div>
                </a>
            </cmui-list-item>
        </cmui-list>
    </div>
    <div class="fixed-full bg-white scroll-container-y paddingh20"
         style="top:48px"
         v-if="suggest.length">
        <ul>
            <li v-for="(item, index) in suggest"
                class="borderb padding20 flex-container"
                :data-scp="`search.${index}`"
                @click="toResult(item)">
                <span v-html="formatRSHTML(item)"></span>
                <i class="icon icon-store-skip fs-12" @click.stop="inputChange(item)"></i>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import itemService      from 'spa/lib/api/item.js';
import searchwebService from 'spa/lib/api/searchweb.js';
import maibisheng       from 'spa/component/product/maibisheng.vue';

let serverName
	, seviceHandler
	, history
	, queryCompleteWords = _.debounce((word, vm)=>{
		seviceHandler({
			data: {
				word
			}
		}).then((data)=>{
			vm.suggest = data.suggest;
		}).catch((data)=>{
			console.log( data );
		});
	}, 200)
	;

export default {
	name: 'search'
	, config: {
		page: {
			value: '07.searchword'
			, hashParam: 'type'
		}
	}
	, components: {
		maibisheng
	},
    data(){
		return {
			history
			, placeholder: ''
			, value: ''
			, type: 'mall'
			, hotWords: null
			, hotWordsUp: []
			, hotWordsLow: []
			, suggest: []
			, fromPage: ''
			, searchFromPage: ''
			, storeIds: null
			, placeholderUrl: ''
			, defaultWords: []
			, saleProduct: []
			, product: []
            // 个推
			, recProducts: []
			, historySearchType: 2  // 1: 显示一行，2: 显示两行
		};
	}
	, beforeCreate(){
		history = _(tg.getCookie('freshSearchKeys')).split(',').uniq().compact().slice(0, 10).value();
	}
	, created(){
		this.type = tg.getParams().hashParams.type || 'mall';
		this.placeholder = tg.getParams().hashParams.placeholder ? decodeURIComponent( tg.getParams().hashParams.placeholder ) : '搜索专柜或商品';
		this.storeIds = tg.getParams().hashParams.storeIds || null;

		switch( this.type ){
			case 'mall':
				seviceHandler = searchwebService.queryMallWords;
				this.historySearchType = 1;
				break;
			case 'super':
				seviceHandler = searchwebService.queryMarketWords;
				this.historySearchType = 1;
				// serverName='queryMarketWords';
				break;
			case 'overseas':
				seviceHandler = searchwebService.queryOverseaWords;
				// serverName='queryOverseaWords';
				break;
			case 'index':
				seviceHandler = searchwebService.queryIndexWords;
				this.historySearchType = 1;
				// serverName='queryIndexWords';
				break;
			case 'toHome':
				seviceHandler = searchwebService.queryMarketWords;
				// serverName='queryMarketWords';
				break;
		}

		this.fromPage = tg.getParams().hashParams.fromPage;
		this.searchFromPage = tg.getParams().hashParams.searchFromPage;

		let typeAction = {
				index: 1
				, oversea: 2
				, counterSale: 3
				, super: 4
				, mall: 4
			};

		tg.midway({
			url: '/search/index/initNew.node'
			, data: {
				type: typeAction[this.type],
                storeId: typeAction[this.type] === 4 ? tg.getCookie('storeId') : null
			}
		}).then((data)=>{
			let defaultWords = _.get(data, 'defaultWord.items');

			this.defaultWord = defaultWords || [];

			if( _.get(defaultWords, 'length') ){
                // 逛店搜索中间页默认词需要与逛店页搜索默认词一致
                let sample = {};
                if (this.type === 'super' || this.type === 'mall') {
                    sample = _.sample(defaultWords.filter(item=>{
                        return item.title === this.placeholder && item.frontShow;
                    }));
                } else {
                    sample = _.sample(defaultWords.filter(({frontShow})=>{
                        return frontShow;
                    }));
                }
				this.placeholder = sample.title;
				this.placeholderUrl = sample.clickUrl;
			}

			this.hotWords = _.get(data, 'hotWords.items');
            if (this.hotWords && this.hotWords.length) {
                if (this.hotWords.length % 2 !== 0) {
                    this.hotWords.pop(1);
                }
                this.hotWords.forEach((item, index) => {
                    if (index % 2 === 0) {
                        this.hotWordsUp.push(item);
                    } else {
                        this.hotWordsLow.push(item);
                    }
                })
            }
			this.$nextTick(()=>{
				tg.updateTraceProductList();
			});
		});
		tg.midway({
			url: '/shopping/page/sale/list.node'
			, listCom: ['product']
		}, this).then((data)=>{
            this.product = data.product;
        });

        if (this.type === 'index') {
            // 个推数据
            tg.autoMidway({
                url: '/shopping/page/rec.node',
                listCom: ['recProducts']
            }, this);
        }

		// 进入搜索，在全局添加标识
		localStorage.setItem('intoSearch', '1');
	},
	methods: {
		toResult(words){
			let str = maple.url().relative.replace(this.$router.history.current.path, this.$router.history.from.path)
				;

			window.history.replaceState(null, '', str);
			tg.changePage('../search/searchResult.html', {
				words,
				type: this.type,
				fromPage: this.fromPage,
				scp: tg.page,
				storeIds: this.storeIds,
				searchFromPage: this.searchFromPage
			});
		}
		, search(){
			let value = this.value || this.placeholder
				;

			if( value === '麦西暖心礼' ){
				tg.replacePage('/tempActivity/drawPlate.html?id=169470');
				return;
			}

			let matchDefault = _( this.defaultWord ).find( item=>item.title === value )
				, matchValue = _.find(this.hotWords, ['title', value])
				;

			if( matchDefault ){
				tg.changePage( matchDefault.clickUrl );
			}
			else if( matchValue ){
				tg.changePage( matchValue.clickUrl );
			}
			else{
				history.unshift( value );
				tg.setCookie('freshSearchKeys', _.uniq( history ).join(','));
				this.toResult( value );
			}
		}
		, inputChange(value){
			this.value = value;
			queryCompleteWords(value, this);
		}
		, formatRSHTML(item){
			let reg = new RegExp(this.value, 'g')
				;

			return item.replace(reg, $0=>`<span style="color:#666666">${$0}</span>`);
		}
		, clearHistory(){
			this.history = [];
			tg.removeCookie('freshSearchKeys');
		}

		, cancelSearch(){
			// 取消搜索，删除全局标识
			localStorage.removeItem('intoSearch');

			this.$router.back();
		}
	}
};
</script>

<style lang="scss" type="text/scss">
.btn-search {
    font-size: 13px;
    overflow: hidden;
    float: left;
    max-width: 100%;
    border-radius: 500px;
    min-width: 20px;
    line-height: 12px;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    color: #666;
    position: relative;
    background: #f5f5f5;
    padding: 8px 17px;
    margin: 10px 10px 0 0;
    border: 1px solid transparent;
    &.red{
        color: #ff6565;
        border: 1px solid #ff6565;
        background-color: white;
    }
}
.hot-search {
    font-size: 13px;
    overflow: hidden;
    float: left;
    max-width: 100%;
    border-radius: 500px;
    min-width: 20px;
    line-height: 12px;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    color: #666;
    position: relative;
    padding: 8px 17px;
    margin: 10px 10px 0 0;
    border: 1px solid transparent;
    &.red{
        color: #ff6565;
        border: 1px solid #ff6565;
        background-color: white;
    }
}
.cmui-input{
    input[type=search]:focus{border-color:#cccccc;}
}
.searchNew.form{
    & input{
        background-color:#EEEFF4 !important;
        border:0 !important;
        padding: 2px 40px 2px 34px !important;
    }
    & .input-search {
        left: 0;
    }
    &:before {
        color: #222;
        line-height: 30px !important;
    }
}
.searchHot{
  background:#F3F4F5;

}
.br8{
  border-radius:8px;
}
</style>