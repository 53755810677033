!function(factory){
	if( typeof define === 'function' && define.amd ){
		define(function(){
			return factory();
		});
	}
	else{
		factory();
	}
}(function(){
	let Class = _init()
        , that = window._tgs = new Class()
        ;

	that.config = {};
	that.config.disabled = false;

	if( /51tiangou\.com/.test(document.domain) ){
		that.config.domain = '51tiangou.com';
		that.config.host = '//log.51tiangou.com';
	}
	else if( /test\.66buy\.com\.cn/.test(document.domain) ){
		that.config.domain = 'test.66buy.com.cn';
		that.config.host = '//log.test.66buy.com.cn';
	}
	else if( /pre\.66buy\.com\.cn/.test(document.domain) ){
		that.config.domain = 'pre.66buy.com.cn';
		that.config.host = '//log.pre.66buy.com.cn';
	}
	else if( /dev\.66buy\.com\.cn/.test(document.domain) ){
		that.config.domain = 'dev.66buy.com.cn';
		that.config.host = '//log.dev.66buy.com.cn';
	}
	else{
		that.config.disabled = true;
	}

	/* 访问路径不包含 51tiangou.com 不统计 */
	that.config.uuidKey = 'tgs_uuid';
	that.config.entryKey = 'tgs_entry';
	that.config.firstTimeKey = 'first_time';
	that.config.sessionKey = 'tgs_session';

	that.data = {
		uuid: that.getUUID()
		, referrer: document.referrer
		, url: location.pathname.substring(1)
		, domain: document.domain
		, session: that.getCookie( that.config.sessionKey )
		, firstTime: that.getCookie( that.config.firstTimeKey )
	};

	window.onbeforeunload = function(){
		let session = that.data.session
            ;

		if( session != null ){
			that.setCookie(that.config.sessionKey, session, {
                expires: 60 * 10    /* 秒 */
            });
		}

		let firstTime = that.data.firstTime
            ;

		if( firstTime != null ){
			that.setCookie(that.config.firstTimeKey, firstTime, {
                expires: 100 * 24 * 60 * 60 * 365   /* 100 年过期 */
            });
		}

		/* 获取 uuid，初始化或者补全相关 uuid 存储地方 */
		that.getUUID();
	};

	function _track(ops, extraData, systemUrl/*系统调用分析接口url*/){
		let that = this
            ;

		if( that.config.disabled ){
			return;
		}

		var ops = ops || ({});

		let cfg = that.config
            , data = that.data
            , qq = that.toQueryString( extraData )
            , url = ops.url || ops.event || data.url
            , symbol = /\?/.test( url ) ? '&' : '?'
            , extra = (qq ? (symbol + qq) : '')
            , cityId = that.rq('cityId') || that.getCookie('cityId') || ops.cityId
            , storeId = that.rq('storeId') || that.getCookie('storeId') || ops.storeId
            , counterId = ops.counterId || ''
            , scp = that.rq('scp')
            , params = {
				uu: data.uuid
				, ur: encodeURIComponent(url + extra)
				, or: encodeURIComponent(window.location.href)
				, gl: that.getCookie('global') || 'webapp'
				, re: encodeURIComponent(ops.referrer || data.referrer)
				, mi: that.getCookie('memberId')
				, ci: cityId
				, si: storeId
				, cc: that.rq('counterId') || counterId.toString()
				, dn: document.domain
				/* first 的定义：
				 * 第一次进入天狗网- （扫码，微信，百度搜索）
				 * */
				// fi: history.length == 1 || ops.first || data.referrer === "" || !/51tiangou.com/.test(data.referrer) || undefined,
				, lat: that.getCookie('lat')
				, lon: that.getCookie('lon')
				, so: data.source
				, rs: data.results
				, ws: data.whereabouts
				, pt: that.getPlatform()
				, scp: scp
				// 需求 #33149 新增字段
				, position: _.get(data, 'position')
                , style: _.get(data, 'style')
                , paging: _.get(data, 'paging')
				// 未埋点的页面以 *.*.*.* 来代替，这样可以找出哪些页面没有埋点
				, page: ops.page || '*.*.*.*'
			}
            ;

		that.get(systemUrl, params);
	}

	function _init(){
		function TGS(){}

		TGS.prototype.get = function(url, params){
			let that = this
                , q = that.toQueryString( params )
                , hm = document.createElement('img')
                ;

			hm.src = url + '?t=' + (+new Date()) + (q ? ('&' + q) : '');

			let s = document.getElementsByTagName('body')[0]
                ;

			hm.style.display = 'none';
			s.parentNode.insertBefore(hm, s);
		};
		TGS.prototype.baoguang = function(scp, bk, traceId, storeId){
			let that = this
                ;

			if( that.config.disabled || !scp ){
				return;
			}

			let data = that.data
                , params = {
					uu: data.uuid
					, gl: that.getCookie('global') || 'webapp'
					, scp: scp
					, bk: bk
					, mi: that.getCookie('memberId')
					, traceId: traceId || null
					, storeId: storeId || that.getCookie('storeId')
				}
                , systemUrl = this.host() + '/ep.gif'
                ;

			that.get(systemUrl, params);
		};
		/**
		 * 传入参数： scp 即可
		 */
		TGS.prototype.trackSCP = function(scp, bk, traceId){
			let that = this
                ;

			if( that.config.disabled || !scp ){
				return;
			}

			let data = that.data
                , params = {
					uu: data.uuid
					, gl: that.getCookie('global') || 'webapp'
					, scp: scp
					, bk: bk
					, mi: that.getCookie('memberId')
					, traceId: traceId
				}
                , systemUrl = this.host() + '/scp.gif';

			that.get(systemUrl, params);
		};

		TGS.prototype.trackPage = function(ops){
			ops = ops || ({});
			ops.type = 1;
			_track.call(this, ops, ops.data || ({}), this.host() + '/tgs.gif');
		};

		TGS.prototype.trackSearch = function(key, data){
			if( !key ){
				return;
			}

			let that = this
                , ops = {}
                ;

			that.data = that.data || ({});
			_.each(data, (value, key)=>{
				that.data[key] = value;
			});
			// that.data = that.data || ({}),
			//     that.data["results"] = data.results,
			//     that.data["whereabouts"] = data.whereabouts,
			//     that.data["source"] = data.source,
			ops.url = key;

			_track.call(this, ops, {}, this.host() + '/sr.gif');
		};

		TGS.prototype.setCookie = function(key, value, options){
			let that = this
                , a = new Date()
                ;

			options = options || {};
			options.domain = options.domain || that.config.domain;

			if( typeof options.expires === 'number' ){
				/* 精确到秒 */
				let days = options.expires
                    , t = options.expires = new Date()
                    ;

				t.setTime(+t + days * 1000);
			}

			if( value == null ){
				options.expires = new Date();
			}

			document.cookie = [
				key, '=', value,
				options.expires ? '; expires=' + options.expires.toGMTString() : '',
				options.domain ? '; domain=' + options.domain : '',
				options.secure ? '; secure' : '',
				'; path=/'
			].join('');
		};

		TGS.prototype.getCookie = function(key){
			return (key = RegExp('(^| )' + key + '=([^;]*)(;|$)').exec( document.cookie )) ? key[2] : null;
		};

		TGS.prototype.toQueryString = function(data){
			let that = this
                , queryString = []
                ;

			switch( that.type(data) ){
				case 'array':
					that.each(data, function(index, value){
						let d = that.toQueryString( value )
                            ;

						if( d && d !== 0 ){
							queryString.push( d );
						}
					});
					break;
				case 'object':
					that.each(data, function(key, value){
						switch( that.type(value) ){
							case 'array':
								that.each(value, function(i, v){
									(v != null || v !== '') && queryString.push(key + '=' + v);
								});
								break;
							case 'object':
								that.each(value, function(i, v){
									(v != null || v !== '') && queryString.push(i + '=' + v);
								});
								break;
							case 'function':
							case 'undefined':
							case 'error':
							case 'regexp':
								break;
							default:
								(value != null || value !== '') && queryString.push(key + '=' + value);
								break;
						}
					});
					break;
				case 'function':
				case 'undefined':
				case 'error':
				case 'regexp':
					return '';
				default:
					return data;
			}

			return queryString.join('&');
		};

		TGS.prototype.getPlatform = function(){
			/* 在电脑上，还是在 pc 上 */
			return 'c'; // p
		};

		TGS.prototype.each = function(data, callback){
			for( let key in data ){
				let value = data[key]
                    ;

				if( data.hasOwnProperty(key) && value != null ){
					callback(key, value);
				}
			}
		};

		TGS.prototype.type = function(obj){
			let t = typeof obj
                ;

			if( t !== 'object' ){
				return t;
			}

			if( obj.concat != null ){
				return 'array';
			}

			if( obj.compile != null ){
				return 'regexp';
			}

			if( obj === null ){
				return 'undefined';
			}

			if( obj.name === 'Error' ){
				return error;
			}

			return t;
		};

		TGS.prototype.rq = function(name){
			let reg = new RegExp('(\\?|&)' + name + '=([^&(/#)]*)', 'gm')
                , r = window.location.href.substr(0).match( reg )
                ;

			if( r != null ){
				let v = []
                    ;

				r.forEach(function(k){
					k = k.replace(new RegExp('(\\?|&)' + name + '='), '');

					if( k ){
						v.push( k );
					}
				});

				if( v.length === 1 ){
					return v[0];
				}

				if( v.length === 0 ){
					return null;
				}
				else{
					return v;
				}
			}
			else{
				return null;
			}
		};

		/*TGS.prototype.uuid = function (len, radix) {
			var CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
			var chars = CHARS, uuid = [], i;
			radix = radix || chars.length;

			if (len) {
			  for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
			} else {
			  var r;
			  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
			  uuid[14] = '4';

			  for (i = 0; i < 36; i++) {
				if (!uuid[i]) {
				  r = 0 | Math.random()*16;
				  uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
				}
			  }
			}
			return uuid.join('') + (+new Date());
		}*/

		TGS.prototype.host = function(){
			return this.config.host;
		};

		TGS.prototype.makeurl = function(url){
			if( !url || url.indexOf('/') !== 0 ){
				return null;
			}

			let protocol = window.location.protocol
                , port = location.port && (':' + location.port)
                , path = location.pathname
                ;

			return protocol + '//' + document.domain + port + url;
		};

		TGS.prototype.getUUID = function(){
			let that = this
                , uuid
                , uuidKey = that.config.uuidKey
                , sessionUUID = ''
                , localUUID = ''
                , cookieUUID = that.getCookie( uuidKey )
                , pageUUID = that.uuid
                ;

			try{
				sessionUUID = sessionStorage.getItem( uuidKey );
				localUUID = localStorage.getItem( uuidKey );
			}
			catch(e){}

			/* 先取 localstorage，再取 sessionStorage，再取 cookie，再取缓存在页面的 */
			uuid = cookieUUID || localUUID || sessionUUID || pageUUID;
			// 为解决线上问题：未登录状态时，tgs接口中uuid值为undefined的问题，当取不到uuid时前端生成uuid
			if (!uuid || uuid == undefined || uuid == 'undefined' || uuid == '' || uuid == null) {
				let s = [];
				let hexDigits = "0123456789abcdef";
				for (let i = 0; i < 36; i++) {
					s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
				}
				s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
				s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
				s[8] = s[13] = s[18] = s[23] = "-";

				uuid = s.join("");
			}

			try{
				if( uuid !== localUUID ){
					localStorage.setItem(uuidKey, uuid);
				}
				if( uuid !== sessionUUID ){
					sessionStorage.setItem(uuidKey, uuid);
				}
			}
			catch(e){}

			if( pageUUID !== uuid ){
				that.uuid = uuid;
			}

			if( uuid !== cookieUUID ){
				that.setCookie(that.config.uuidKey, uuid, {
                    expires: 36500 * 60 * 60 * 24   /* 100 年 */
                });
			}

			return uuid;
		};

		return TGS;
	}

	return _tgs;
});