import domain                               from 'javascript/libs/tg/domain.js';
import tgUrl                                from 'javascript/libs/tg/util/url.js';
import {setCookie, getCookie, removeCookie} from './util.js';
import wechatService                        from './api/weixin.js';
import memberService                        from './api/member.js';

let wxReady = false
    , functionList = []
    ;

class WeChat{
	constructor(){}

	init(){
		let _this = this
            ;

		return new Promise((resolve, reject)=>{
			if( !maple.device.weixin ){
				return resolve();
			}

			_this.loadSDK().then(()=>{
				return wechatService.sign({
					data: {
						url: location.href
					}
				});
			}).catch(()=>{
				reject();
			}).then(data=>{
                data.jsApiList.push('chooseImage', 'scanQRCode', 'uploadImage', 'downloadImage', 'startRecord', 'stopRecord', 'onVoiceRecordEnd', 'playVoice', 'pauseVoice', 'stopVoice', 'onVoicePlayEnd', 'uploadVoice', 'downloadVoice', 'translateVoice', 'getLocation');
				// 新版分享 api
				data.jsApiList.push('updateAppMessageShareData', 'updateTimelineShareData');

				data.openTagList = ['wx-open-launch-weapp'];

				return _this.sign( data );
			}).then(()=>{
				wxReady = true;

				while( functionList.length ){
					let fn = functionList.splice(0, 1)[0]
                        ;

					if( _.isFunction(fn) ){
						fn();
					}
					else{
						console.warn('fn is not a function');
					}
				}

				resolve();
			});
		});
	}

	loadSDK(){
		return new Promise((resolve)=>{
			// $.getScript('//res.wx.qq.com/open/js/jweixin-1.0.0.js',resolve);
			$.getScript('//res.wx.qq.com/open/js/jweixin-1.6.0.js', resolve);
		});
	}

	runFunction(fn){
		wxReady ? fn() : functionList.push( fn );
	}

	sign(conf){
		return new Promise((res)=>{
			wx.config( conf );
			wx.ready( res );
		});
	}

	setShare(opt={}){
		this.runFunction(()=>{
			opt = opt || {};
			opt.imgUrl = opt.imgUrl || '//image1.51tiangou.com/tgou2/img2/tgou_logo3.png!s';
			opt.imageUrl = opt.imageUrl || opt.imgUrl;
			// 显示微信功能菜单
			wx.showMenuItems({
				menuList: [
					'menuItem:share:appMessage',
					'menuItem:share:timeline',
					'menuItem:share:qq',
					'menuItem:share:weiboApp',
					'menuItem:favorite',
					'menuItem:share:facebook',
					'menuItem:share:QZone'
				]
			});
			opt.link = getShareLink(opt.link || opt.url);

			let friend = 'WT'
				,
				timeline = 'CF'
			;

			// 发送给朋友
			wx.onMenuShareAppMessage && wx.onMenuShareAppMessage({
				title: opt.title || '【大商天狗网】 不一样的逛街方式',    // 分享标题
				desc: opt.subTitle || (
					location && location.href) || 'm.51tiangou.com',            // 分享描述
				// link: opt.link || location.href,                    // 分享链接
				link: tgUrl.addParams(opt.link, {
					ENT: friend
				})
				,
				imgUrl: opt.imageUrl.replace(/^\/\//, 'http://'),                                 // 分享图标
				success: opt.success,                               //分享成功的回调
				cancel: opt.cancel                                  //取消分享的回调
			});
			// 分享给朋友新版 api
			wx.updateAppMessageShareData && wx.updateAppMessageShareData({
				title: opt.title || '【大商天狗网】 不一样的逛街方式',   // 分享标题
				// link: opt.link,                                         // 分享链接
				desc: opt.subTitle || (
					location && location.href) || 'm.51tiangou.com',            // 分享描述
				link: tgUrl.addParams(opt.link, {
					ENT: timeline
				})
				,
				imgUrl: opt.imageUrl.replace(/^\/\//, 'http://'),                                     // 分享图标
				success: opt.success,                                   //分享成功的回调
				cancel: opt.cancel                                      //取消分享的回调
			});

			//发送给朋友圈
			wx.onMenuShareTimeline && wx.onMenuShareTimeline({
				title: opt.friendTitle || '【大商天狗网】 不一样的逛街方式',   // 分享标题
				// link: opt.link,                                         // 分享链接
				link: tgUrl.addParams(opt.link, {
					ENT: timeline
				})
				,
				imgUrl: opt.imageUrl.replace(/^\/\//, 'http://'),                                     // 分享图标
				success: opt.success,                                   //分享成功的回调
				cancel: opt.cancel                                      //取消分享的回调
			});
			// 分享给朋友圈新版 api
			wx.updateTimelineShareData && wx.updateTimelineShareData({
				title: opt.friendTitle || '【大商天狗网】 不一样的逛街方式',   // 分享标题
				// link: opt.link,                                         // 分享链接
				link: tgUrl.addParams(opt.link, {
					ENT: timeline
				})
				,
				imgUrl: opt.imageUrl.replace(/^\/\//, 'http://'),                                     // 分享图标
				success: opt.success,                                   //分享成功的回调
				cancel: opt.cancel
			});

			function getShareLink(link){
				//如果link得域名与当前域名不同的话
				if( link && link.indexOf(domain.baseService) < 0 ){
					return 'https:' + domain.baseService + '/qr.html?id=redirect_' + encodeURIComponent(link);
				}
				else{
					return link;
				}
			}
		});
	}

	// 微信下获取 openId
	// * 情况：1.本地没有openId，并且url上没有code参数 >> 立即跳转页面去换取微信code
	// *      2.本地没有openId，但是url上有code参数 >> 调取weixinService的接口获取openId
	// *      3.本地存在openId，则返回openId
	getOpenid(opt={}){
		return new Promise((resolve)=>{
			this.runFunction(()=>{
				let urlParams = maple.url()
					, code = urlParams.params.code
					, appId = opt.appId || domain.appId
					, localOpenId = getCookie('openid')
					, localUnionId = getCookie('unionid')
					, localOpenIdCypher = getCookie('openid_cypher')
					, expireDay = opt.expire || '365d'
					;

				// 若 code 为数组，取最后一个
				if( Array.isArray(code) ){
					code = code[code.length - 1];
				}

				// debugger
				// 对应情况3
				if( localOpenId && localOpenIdCypher && localUnionId ){
					resolve();
				}
				// 对应情况2
				// code 长度小于 26 则视为无效的 code
				else if( code
					&& (!localOpenId || !localOpenIdCypher || !localUnionId)
					&& code.length > 26
					&& urlParams.params.state === '_static_openid_flag_' ){

					wechatService.getOpenId({
						data: {
							code: code
							, appId: appId
						}
					}).then((data)=>{
						data.openid && setCookie('openid', data.openid, expireDay);
						data.unionid && setCookie('unionid', data.unionid, expireDay);
						data.openid_cypher && setCookie('openid_cypher', data.openid_cypher, expireDay);

						// /**
						//  * 2021-09-03
						//  * 李银要求使用 openAppId 获取到的 code 才去调用 wechatLogin 接口
						//  *
						//  * 因为 openAppId 需要用户点一些确定，不是静默的
						//  * 现在的登录逻辑都是绑定 unionid 走的，之前老的登录是绑定 openid 方式
						//  * 而 appId 这个现在静默方式只能拿到 openid，拿不到 unionid，所以就先给下了吧
						//  * 就屏蔽，不去调就行了
						//  *
						//  * 以上原话。。。
						//  * 所以将 code 设为空，回调中判断空就不掉 wechatLogin 接口
						//  * */
						// if( appId !== domain.openAppId ){
						// 	code = undefined;
						// }

						resolve( code );
					});
				}
				// 对应情况1
				else if( !code
					&& (!localOpenId || !localOpenIdCypher || !localUnionId) ){

					var locationStr = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + (appId || domain.appId)
						;

					locationStr += '&redirect_uri=' + encodeURIComponent( window.location.origin + window.location.pathname + window.location.search + window.location.hash );
					locationStr += '&response_type=code&scope=snsapi_base&state=_static_openid_flag_#wechat_redirect';
					// 跳转至微信 authorize 地址，获取 code
					location.replace( locationStr );
				}
				else{}
			});
		});
	}

	// 微信下联合登录
	wechatLogin(code){
		return new Promise((resolve)=>{
			memberService.wechatLogin({
				data: {
					unionId: getCookie('unionId')
					, code
				}
				, contentType: 'application/json'
				, tgError: function(data){
					data = data || {};

					if( data.code === 503 ){
						location.reload();
					}
					else{
						resolve();
					}
				}
			}).then((data)=>{
				setCookie('token', data.token, '7d');
				setCookie('vip', +data.isVip, '7d');

				maple.localData.remove('ssoConfirmed');
				resolve();
			}).catch(()=>{
				resolve();
			});
		});
	}

	// 微信下获取信息
	getUsrInfo(opt={}){
		return new Promise((resolve)=>{
			let appId = opt.appId || opt.appid || domain.appId
				, isDefaultAppId = appId === domain.appId
				, callback = opt.callback || function(){}
				, urlParams = maple.url().params
				, STATE_NAME = 'wechat_util'
				, COOKIE_EXPIRE_DAYS = 7
				, openid = getCookie( appId )   // cookie 中的 openId
				, userInfo_c = isDefaultAppId ? getCookie('userInfo') : getCookie( 'userInfo_' + appId )
				, access_token_c = isDefaultAppId ? getCookie('access_token') : getCookie( 'access_token_' + appId )
				, code_url = _.isArray( urlParams.code ) ? urlParams.code[urlParams.code.length - 1] : urlParams.code
				, state_url = _.isArray( urlParams.state ) ? urlParams.state[urlParams.state.length - 1] : urlParams.state
				;

			if( userInfo_c ){
				// callback(JSON.parse(userInfo_c));
				resolve();
			}
			else if( access_token_c && openid ){
				wechatService.getUserInfoByAccessToken({
					data: {
						access_token: access_token_c
						, openid: openid
					}
					, tgError: function(errorData){
						if( errorData.code === 503 ){
							// access_token 已经过期了,清楚缓存中的 access_token
							isDefaultAppId ? setCookie('access_token', '') : setCookie('access_token_' + appId, '');
							isDefaultAppId ? removeCookie('access_token', '') : removeCookie('access_token_' + appId, '');
							location.replace(_getWxAuthUrl(appId));
						}
					}
				}).then((data)=>{
					console.log('isDefaultAppId', isDefaultAppId);
					// 缓存用户信息
					isDefaultAppId ? setCookie('userInfo', JSON.stringify( data ), COOKIE_EXPIRE_DAYS) : setCookie('userInfo_' + appId, JSON.stringify( data ), COOKIE_EXPIRE_DAYS);
					// 保存 openId
					_setOpenidByAppid({
						appId: appId
						, openId: data.openid
						, openid_cypher: data.openid_cypher
					});
					resolve();
				});
			}
			else if( code_url && state_url === STATE_NAME ){
				// 已授权（刚授权），来获取 access_token
				wechatService.getUserInfoByAppId({
					data: {
						code: code_url
						, appid: appId
					}
				}).then(data=>{
					let access_token = data.access_token
						, d = new Date()
						, time = d.getTime() + 7200 * 1000  // 缓存两小时
						;

					isDefaultAppId ? setCookie('access_token', access_token, new Date( time )) : setCookie('access_token_' + appId, access_token, new Date( time ));
					// alert(JSON.stringify(data && data.userInfo));
					// 保存用户信息
					try{
						isDefaultAppId ? setCookie('userInfo', JSON.stringify(data && data.userInfo), COOKIE_EXPIRE_DAYS) : setCookie('userInfo_' + appId, JSON.stringify(data && data.userInfo), COOKIE_EXPIRE_DAYS);
					}
					catch(e){
						console.info( e.message );
					}
					// 保存 openid
					this._setOpenidByAppid({
						appId: appId
						, openId: data.userInfo && (data.userInfo.openid || data.userInfo.openId)
						, openid_cypher: data.openid_cypher // 这里把 openId 当做 openId——cypher 来处理
					});
					resolve();
				});
			}
			else{
				// 未授权，需跳转至微信授权页面
				location.replace( _getWxAuthUrl(appId) );
			}

			function _setOpenidByAppid(params){
				let appId = params.appId || params.appid || domain.appId
					, openId = params.openId || params.openid
					, openid_cypher = params.openid_cypher
					, expireDay = params.expire || 365
					;

				if( appId === domain.appId ){
					// 设置对应的 openId 和 openid_cypher
					openId && setCookie('openid', openId, expireDay);
					openid_cypher && setCookie('openid_cypher', openid_cypher, expireDay);
				}
				else{
					// 设置对应的 openId 和 openid_cypher
					openId && setCookie('openid_' + appId, openId, expireDay);
					openid_cypher && setCookie('openid_cypher_' + appId, openid_cypher, expireDay);
				}
			}

			function _getWxAuthUrl(appid){
				return 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='
					+ appid
					+ '&redirect_uri='
					+ encodeURIComponent( window.location.origin + window.location.pathname + window.location.search + window.location.hash )
					+ '&response_type=code&scope=snsapi_userinfo&state='
					+ STATE_NAME
					+ '#wechat_redirect';
			}
		});

	}

	hideMenu(){
		this.runFunction(()=>{
			wx.hideMenuItems({
				menuList: ['menuItem:share:appMessage'
					, 'menuItem:share:timeline'
					, 'menuItem:share:qq'
					, 'menuItem:share:weiboApp'
					, 'menuItem:favorite'
					, 'menuItem:share:facebook'
					, 'menuItem:share:QZone']
			});
		});
	}

	setOpenidByAppid(params){
		let appId = params.appId || params.appid || domain.appId
			, openId = params.openId || params.openid
			, openid_cypher = params.openid_cypher
			, expireDay = params.expire || 365
			;

		if( appId === domain.appId ){
			// 设置对应的 openId 和 openid_cypher
			openId && setCookie('openid', openId, expireDay);
			openid_cypher && setCookie('openid_cypher', openid_cypher, expireDay);
		}
		else{
			// 设置对应的 openId 和 openid_cypher
			openId && setCookie('openid_' + appId, openId, expireDay);
			openid_cypher && setCookie('openid_cypher_' + appId, openid_cypher, expireDay);
		}
	}
}

export default new WeChat;