import Super from "./class";
let serverList={
	get:{
		updDelItemCollection: '/mallActivityProductToFitting/updDelItemCollection',
		// 全部运营分类树
		supplierCategoryTree: '/front/supplierCategory/tree',
		// 榜单类型
		rankTypeList: '/front/rank/type/list',
		// 排行榜榜单列表
		rankList: '/front/rank/list',
		// 热卖排行榜
		hotSale: '/front/rank/hotSale',
		// 排行榜子榜商品列表
		rankItemList: '/front/rank/item/list',
		// 热卖页下面的推荐榜单
		hotRankList: '/front/rank/hotSale/recommend'
	},
	post:{
		flagshipStores:'/brandStreet/flagshipStores',
		itemTrace:'/front/listing/itemTrace', //获取商品溯源信息
		itemTraceWithSku:'/front/listing/itemTraceWithSku',
		queryBrandByTopBizId:'/frontBizCategory/queryBrandByTopBizId',
		skuInfo:'/front/market/skuInfo',
		addShoppingCart:'/front/market/addShoppingCart',
		queryOverseaWords:'/search/oversea/queryCompleteWords',
		queryMarketWords:'/search/market/queryCompleteWords',
		queryMallWords:'/search/mall/queryCompleteWords',
		queryIndexWords:'/search/platform/queryCompleteWords'
	},
};
export default new Super(serverList,'item');