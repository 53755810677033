var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paddingv20" },
    [
      _c(
        "form",
        {
          staticClass: "pos-r paddingh20",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.search.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "cmui-input",
            {
              staticClass: "marginb20 searchNew",
              attrs: {
                type: "search",
                align: "right",
                "target-class": "small radius",
                placeholder: _vm.placeholder,
              },
              on: { input: _vm.inputChange },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "marginl20 text-dark",
                  on: { click: _vm.cancelSearch },
                },
                [_vm._v("取消")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.value,
                  expression: "!value",
                },
              ],
              staticClass: "pos-a fs-13 text-darker",
              staticStyle: { right: "62px", top: "6px" },
              attrs: {
                href: "/store.html#/selectStore",
                "data-scp": "searchshop.0",
                "data-bk": "type-" + _vm.type,
              },
            },
            [
              _c("i", {
                staticClass: "icon icon-search_shop",
                staticStyle: { "margin-right": "3px" },
              }),
              _vm._v("搜门店\n        "),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._.get(_vm.history, "length")
        ? _c(
            "div",
            {
              staticClass: "paddingv10 paddingh20 clearfix pos-r",
              staticStyle: { overflow: "hidden" },
              style:
                _vm.historySearchType === 1
                  ? "max-height: 76px;"
                  : "max-height: 110px;",
            },
            [
              _c("p", { staticClass: "paddingb10 text-bolder" }, [
                _vm._v("最近搜索"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pos-a",
                  staticStyle: { right: "10px", top: "7px" },
                  on: { click: _vm.clearHistory },
                },
                [_c("i", { staticClass: "icon icon-delete text-light" })]
              ),
              _vm._v(" "),
              _vm._l(_vm.history, function (item, index) {
                return _c(
                  "span",
                  {
                    staticClass: "btn-search",
                    attrs: {
                      "data-bk": "type-" + _vm.type,
                      "data-scp": "freshData." + index,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toResult(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.product.length > 0
        ? _c("maibisheng", { attrs: { data: _vm.product, from: _vm.type } })
        : _vm._e(),
      _vm._v(" "),
      _vm._.get(_vm.hotWords, "length")
        ? _c(
            "div",
            { staticClass: "clearfix" },
            [
              _c("p", { staticClass: "paddingt40 paddingh20 text-bolder" }, [
                _vm._v("热门搜索"),
              ]),
              _vm._v(" "),
              _vm.type === "index"
                ? _c(
                    "div",
                    { staticClass: "marginh20 margint30 scroll-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "dis-b" },
                        _vm._l(_vm.hotWordsUp, function (item, index) {
                          return _c(
                            "div",
                            {
                              staticClass: "dis-i",
                              staticStyle: {
                                "min-width": "160px",
                                padding: "4px",
                                "margin-right": "8px",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "flex-container left searchHot br8 padding10",
                                  attrs: {
                                    href: item.clickUrl,
                                    "data-bk": "type-" + _vm.type,
                                    "data-scp": "hotData." + 2 * index,
                                    "data-trace-id": item.traceId,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-container",
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { "border-radius": "4px" },
                                        attrs: { src: item.imageUrl, alt: "" },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "marginl10" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-limit1 fs-12",
                                        class:
                                          item.hot === 0
                                            ? "text-red"
                                            : "text-black",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(item.title.slice(0, 7)) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-limit1 fs-10",
                                        staticStyle: { color: "#848484" },
                                      },
                                      [_vm._v(_vm._s(item.subTitle))]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dis-b" },
                        _vm._l(_vm.hotWordsLow, function (item, index) {
                          return _c(
                            "div",
                            {
                              staticClass: "dis-i",
                              staticStyle: {
                                "min-width": "160px",
                                padding: "4px",
                                "margin-right": "8px",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "flex-container left searchHot br8 padding10",
                                  attrs: {
                                    href: item.clickUrl,
                                    "data-bk": "type-" + _vm.type,
                                    "data-scp": "hotData." + (2 * index + 1),
                                    "data-trace-id": item.traceId,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-container",
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { "border-radius": "4px" },
                                        attrs: { src: item.imageUrl, alt: "" },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "marginl10" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-limit1 fs-12",
                                        class:
                                          item.hot === 0
                                            ? "text-red"
                                            : "text-black",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(item.title.slice(0, 7)) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-limit1 fs-10",
                                        staticStyle: { color: "#848484" },
                                      },
                                      [_vm._v(_vm._s(item.subTitle))]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _c(
                    "cmui-list",
                    {
                      staticClass: "marginh20 margint30",
                      attrs: { col: 2, space: 20 },
                    },
                    _vm._l(_vm.hotWords, function (item, index) {
                      return _c("cmui-list-item", [
                        _c(
                          "a",
                          {
                            staticClass:
                              "flex-container left searchHot br8 padding10",
                            attrs: {
                              href: item.clickUrl,
                              "data-bk": "type-" + _vm.type,
                              "data-scp": "hotData." + index,
                              "data-trace-id": item.traceId,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img-container",
                                staticStyle: { width: "50px", height: "50px" },
                              },
                              [
                                _c("img", {
                                  staticStyle: { "border-radius": "4px" },
                                  attrs: { src: item.imageUrl, alt: "" },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "marginl10" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "text-limit1 fs-14",
                                  class:
                                    item.hot === 0 ? "text-red" : "text-black",
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "text-limit1 fs-10",
                                  staticStyle: { color: "#848484" },
                                },
                                [_vm._v(_vm._s(item.subTitle))]
                              ),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.recProducts.length
        ? _c(
            "div",
            [
              _c("p", { staticClass: "paddingt40 paddingh20 text-bolder" }, [
                _vm._v("为您推荐"),
              ]),
              _vm._v(" "),
              _c(
                "cmui-list",
                {
                  staticClass: "marginh20 margint30",
                  attrs: { col: 3, space: 10 },
                },
                _vm._l(_vm.recProducts, function (item, index) {
                  return _c("cmui-list-item", [
                    _c(
                      "a",
                      {
                        staticClass: "dis-b marginb10 border-radius border",
                        attrs: {
                          href: item.clickUrl,
                          "data-bk": "item-" + item.id,
                          "data-scp": "ylike." + index,
                          "data-trace-id": item.traceId,
                        },
                      },
                      [
                        _c("div", { staticClass: "img-container" }, [
                          _c("img", {
                            attrs: {
                              src: item.imageUrl,
                              onerror:
                                "this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'",
                              alt: "",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "padding10" }, [
                          _c("p", {
                            staticClass: "text-fixed2 fs-12 text-darker",
                            staticStyle: {
                              "line-height": "1.2",
                              height: "2.4em",
                            },
                            domProps: { textContent: _vm._s(item.title) },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-container left" }, [
                            item.newPrice
                              ? _c("span", { staticClass: "text-red fs-12" }, [
                                  _c("span", { staticClass: "fs-15" }, [
                                    _vm._v(
                                      "¥" + _vm._s(item.newPrice) + " 新人价"
                                    ),
                                  ]),
                                ])
                              : _c("span", { staticClass: "text-red fs-15" }, [
                                  _vm._v("¥" + _vm._s(item.price)),
                                ]),
                            _vm._v(" "),
                            item.price !== item.originalPrice && !item.newPrice
                              ? _c("del", {
                                  staticClass: "text-light fs-11 marginl10",
                                  domProps: {
                                    textContent: _vm._s(
                                      "¥" + item.originalPrice
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.suggest.length
        ? _c(
            "div",
            {
              staticClass: "fixed-full bg-white scroll-container-y paddingh20",
              staticStyle: { top: "48px" },
            },
            [
              _c(
                "ul",
                _vm._l(_vm.suggest, function (item, index) {
                  return _c(
                    "li",
                    {
                      staticClass: "borderb padding20 flex-container",
                      attrs: { "data-scp": "search." + index },
                      on: {
                        click: function ($event) {
                          return _vm.toResult(item)
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.formatRSHTML(item)) },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "icon icon-store-skip fs-12",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.inputChange(item)
                          },
                        },
                      }),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }